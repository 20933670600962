.AccessibleOffline {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(240, 240, 240);
    border-radius: 50%;
}

    .AccessibleOffline > svg {
        display: block;
        color: black;
    }