.animated-badge-container {
    position: relative;
}

.animated-badge {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.animated-badge-pellet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #44b700;
}

.animated-badge-pellet::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: #44b700;
    animation: ripple 1.2s infinite ease-in-out;
}

@keyframes ripple {

    from {
        transform: scale(0.6);
        opacity: 1;
    }

    to {
        transform: scale(1.2);
        opacity: 0;
    }
}