.GuideCategory-name {
    padding: 16px;
    background-color: white;
    margin-top: 24px;
    overflow: hidden;
}

    .GuideCategory-name h2 {
        margin: 0;
    }

.GuideCategory-borderCard {
    border: 2px solid gray;
    border-radius: 4px;
    margin-top: 24px;
}

.GuideCategory-details {
    display: flex;
    align-items: center;
    padding: 12px;
    background-color: rgba(255, 255, 255, 0.5);
    white-space: pre-wrap;
}