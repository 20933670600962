.pellet {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
}

.zb {
    background-color: rgba(25, 118, 210, 0.08);
    color: #1976d2;
}

.hp {
    background-color: rgba(172, 0, 102, 0.08);
    color: #ac0066;
}

.mb {
    background-color: rgba(46, 125, 50, 0.08);
    color: #2e7d32;
}