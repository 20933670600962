.SideMenu {
    position: fixed;
    z-index: 998;
    top: var(--app-bar-height);
    left: 0;
    bottom: 0;
    min-width: var(--side-menu-width);
    width: var(--side-menu-width);
    max-width: var(--side-menu-width);
    overflow: hidden;
    background-color: white;
    box-shadow: 0 8px 8px lightgray;
}

.SideMenu-menuList {
    display: flex;
    flex-direction: column;
}

.SideMenu-version {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 14px;
    font-weight: 500;
    color: gray;
    padding: 12px;
    width: 100%;
}

.NavLink-selected > div {
    background-color: rgba(0, 0, 0, 0.08);
}