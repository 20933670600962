.UserGuide-translations {
    position: relative;
}

.UserGuide-backdrop {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.UserGuide-tab {
    width: 60px;
    height: 28px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    padding: 6px;
    margin-left: 8px;
}

.UserGuide-tabSelected {
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: white;
    min-height: 34px;
}

.UserGuide-tabSelected::before {
    content: '';
    position: absolute;
    display: block;
    bottom: -1px;
    left: -15px;
    width: 16px;
    height: 16px;
    background-color: white;
    clip-path: path("M16 16L0 16C10 16 16 10 16 0L16 16Z");
}

.UserGuide-tabSelected::after {
    content: '';
    position: absolute;
    display: block;
    bottom: -1px;
    right: -15px;
    width: 16px;
    height: 16px;
    background-color: white;
    clip-path: path("M0 16 H 16 C6 16 0 10 0 0 Z");
}

.UserGuide-tabUnselected {
    border-radius: 8px;
    background-color: rgb(255, 255, 255, 0.3);
    cursor: pointer;
}

    .UserGuide-tabUnselected:hover {
        background-color: rgb(255, 255, 255, 0.5);
    }

.UserGuide-saveButton {
    border: none;
    border-radius: 4px;
    padding: 4px 6px;
    cursor: pointer;
    background-color: rgb(255, 255, 255, 0.8);
}

    .UserGuide-saveButton:hover {
        background-color: rgb(255, 255, 255, 1);
    }

.UserGuide-translationsContainer {
    background-color: white;
    padding: 24px;
}