:root {
    --app-bar-height: 64px;
    --side-menu-width: 220px;
}

* {
    box-sizing: border-box; 
}

html, body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    text-decoration: none;
    color: unset;
}

form {
    display: flex;
    flex-direction: column;
}

/* -------------------------------------------------------------------------------------------------- */
/* ---------------------------------------- QL EDITOR LAYOUT ---------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */

#ql-editor-portal {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 510.24px;
}

#ql-editor-portal .ql-container {
    padding: 0px !important;
    margin: 15px !important;
}

#ql-editor-portal .ql-editor {
    font-size: 11px;
}

/* -------------------------------------------------------------------------------------------------- */
/* ---------------------------------------- QL EDITOR LAYOUT ---------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */

.chip {
    width: fit-content;
    overflow: hidden;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.App-content {
    min-height: calc(100vh - var(--app-bar-height));
    margin-top: var(--app-bar-height);
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(22.5deg, rgba(242, 242, 242, 0.03) 0%, rgba(242, 242, 242, 0.03) 16%,rgba(81, 81, 81, 0.03) 16%, rgba(81, 81, 81, 0.03) 26%,rgba(99, 99, 99, 0.03) 26%, rgba(99, 99, 99, 0.03) 73%,rgba(43, 43, 43, 0.03) 73%, rgba(43, 43, 43, 0.03) 84%,rgba(213, 213, 213, 0.03) 84%, rgba(213, 213, 213, 0.03) 85%,rgba(125, 125, 125, 0.03) 85%, rgba(125, 125, 125, 0.03) 100%),linear-gradient(22.5deg, rgba(25, 25, 25, 0.03) 0%, rgba(25, 25, 25, 0.03) 54%,rgba(144, 144, 144, 0.03) 54%, rgba(144, 144, 144, 0.03) 60%,rgba(204, 204, 204, 0.03) 60%, rgba(204, 204, 204, 0.03) 76%,rgba(37, 37, 37, 0.03) 76%, rgba(37, 37, 37, 0.03) 78%,rgba(115, 115, 115, 0.03) 78%, rgba(115, 115, 115, 0.03) 91%,rgba(63, 63, 63, 0.03) 91%, rgba(63, 63, 63, 0.03) 100%),linear-gradient(157.5deg, rgba(71, 71, 71, 0.03) 0%, rgba(71, 71, 71, 0.03) 6%,rgba(75, 75, 75, 0.03) 6%, rgba(75, 75, 75, 0.03) 15%,rgba(131, 131, 131, 0.03) 15%, rgba(131, 131, 131, 0.03) 18%,rgba(110, 110, 110, 0.03) 18%, rgba(110, 110, 110, 0.03) 37%,rgba(215, 215, 215, 0.03) 37%, rgba(215, 215, 215, 0.03) 62%,rgba(5, 5, 5, 0.03) 62%, rgba(5, 5, 5, 0.03) 100%),linear-gradient(90deg, #ffffff,#ffffff);
}

.ListItemCard {
    background-color: white;
    box-shadow: 0 0 8px lightgray;
    margin: 16px;
    overflow: hidden;
}

.ListItemCard > a {
    padding: 8px;
}

.ListItemCard-label {
    display: flex;
    align-items: center;
    flex: 1;
    line-height: 40px;
}

.ListItemCard-label > span {
    margin-left: 8px;
}

@media screen and (min-width: 1200px) {

    .App-content {
        margin-left: var(--side-menu-width);
    }

    .ListItemCard {
        margin: 16px;
    }

    .ListItemCard > a {
        padding: 24px;
    }

    .ListItemCard-label > span {
        margin-left: 16px;
    }

    .ListItemCard-label > svg {
        font-size: 32px;
    }
}