.AppBar {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    padding: 0px 16px;
    min-height: var(--app-bar-height);
    height: var(--app-bar-height);
    max-height: var(--app-bar-height);
    overflow: hidden;
    box-shadow: 0 0 8px lightgray;
    background-color: white;
}

.AppBar-listItemButton {
    min-height: var(--app-bar-height);
    height: var(--app-bar-height);
    max-height: var(--app-bar-height);
    padding: 8px !important;
    justify-content: center !important;
}

.AppBar-logoTitle {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

@media screen and (min-width: 600px) {
    
    .AppBar-logoTitle {
        font-size: 20px;
    }
}